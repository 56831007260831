import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <h1 className="about-title">Om os</h1>
            <p className="about-content">
                Velkommen til <span className="about-highlight">Sølvgade Tattoo</span>, hvor dine tattoverings drømme alle kan blive opfyldt. Jeg, Niels, er en erfaren og passioneret kunstner, som er dedikerede til at skabe skræddersyede tatoveringer, der er lige så unikke som dig.
            </p>
            <img src={process.env.PUBLIC_URL + '/Images/profil.jpg'} alt="Tattoo Studio" className="about-image" />
            <p className="about-content">
                Beliggende i hjertet af byen tilbyder vores studie en behagelig og imødekommende atmosfære for alle vores kunder. Uanset om du leder efter et lille, delikat stykke eller en fuld sleeve, er vi her for at hjælpe dig hele vejen.
            </p>
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2249.1560433586565!2d12.580629077421959!3d55.6862747730597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465253cd44ab5ab9%3A0x7943e976d7f4de43!2sS%C3%B8lvgade%20Tattoo!5e0!3m2!1sen!2sdk!4v1724324584221!5m2!1sen!2sdk"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Sølvgade Tattoo Location"
                ></iframe>
            </div>
        </div>
    );
};

export default About;
