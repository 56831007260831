import React from 'react';
import Slideshow from './Slideshow'; // Import the Slideshow component
import './Speciale.css';

const images = [
    process.env.PUBLIC_URL + '/Images/glitter3.jpg',
    process.env.PUBLIC_URL + '/Images/glitter4.jpg',
    process.env.PUBLIC_URL + '/Images/glitter1.jpg',
    process.env.PUBLIC_URL + '/Images/glitter5.jpg',
    process.env.PUBLIC_URL + '/Images/glitter2.jpg',
];

const Speciale = () => {
    return (
        <div className="speciale-container">
            <h1 className="speciale-title">Vores Specialer</h1>
            <p className="speciale-content">
                Hos Sølvgade Tattoo er vi specialister i en række unikke stilarter, herunder glitter-tatoveringer - muligvis som de eneste i Danmark! Vi mestrer også dotwork, mandala, sort og hvid, samt realisme, men vi er altid klar til at skabe lige præcis den tatovering, du drømmer om.
            </p>
            <h1 className="speciale-title">Glitter-tatoveringer</h1>
            <div className="speciale-slideshow">
                <Slideshow images={images} />
            </div>
        </div>
    );
};

export default Speciale;
