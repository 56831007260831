import React from 'react';
import Slideshow from './Slideshow';
import './Home.css';

const images = [
    process.env.PUBLIC_URL + '/Images/glitter2.jpg',
    process.env.PUBLIC_URL + '/Images/glitter1.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo4.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo3.jpg',
    process.env.PUBLIC_URL + '/Images/glitter3.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo1.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo2.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo5.jpg',
    process.env.PUBLIC_URL + '/Images/tattoo6.jpg',
];

const Home = () => {
    return (
        <div className="home-container">
            <h1 className="home-title">Sølvgade Tattoo</h1>
            <h2 className="home-subtitle">Smugkig på nogle af de tatoveringer vi har lavet</h2>
            <Slideshow images={images} />
        </div>
    );
};

export default Home;
