import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-container">
            <h1 className="contact-title">Kontakt Sølvgade Tattoo</h1>
            <div className="contact-details">
                <div className="contact-item">
                    <i className="fas fa-phone-alt contact-icon"></i>
                    <a href="tel:+4542200116" className="contact-link">42 20 01 16</a>
                </div>
                <div className="contact-item">
                    <i className="fab fa-instagram contact-icon"></i>
                    <a href="https://www.instagram.com/soelvgade.tattoo" target="_blank" rel="noopener noreferrer" className="contact-link">
                        @soelvgade.tattoo
                    </a>
                </div>
                <div className="contact-item">
                    <i className="fab fa-facebook contact-icon"></i>
                    <a href="https://www.facebook.com/profile.php?id=61555017960768" target="_blank" rel="noopener noreferrer" className="contact-link">
                        Sølvgade Tattoo
                    </a>
                </div>
                <img src={process.env.PUBLIC_URL + '/Images/Logo.jpg'} alt="Sølvgade Tattoo Logo" className="about-image" />
            </div>
        </div>
    );
};

export default Contact;
