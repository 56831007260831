import React, { useState, useEffect } from 'react';
import './Slideshow.css';

const Slideshow = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(goToNext, 5000); // 5000ms = 5 seconds
        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [currentIndex]);

    const getClassName = (index) => {
        if (index === currentIndex) return 'slide active';
        if (index === (currentIndex === 0 ? images.length - 1 : currentIndex - 1)) return 'slide prev';
        if (index === (currentIndex === images.length - 1 ? 0 : currentIndex + 1)) return 'slide next';
        return 'slide';
    };

    return (
        <div className="slideshow">
            <button onClick={goToPrevious} className="left-arrow">&#9664;</button>
            <button onClick={goToNext} className="right-arrow">&#9654;</button>
            <div className="slides">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Slide ${index}`}
                        className={getClassName(index)}
                    />
                ))}
            </div>
            <div className="indicators">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slideshow;
