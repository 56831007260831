import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/" className="navbar-logo">Sølvgade Tattoo</Link>
            </div>
            <ul className="navbar-menu">
                <li><Link to="/">Hjem</Link></li>
                <li><Link to="/about">Om os</Link></li>
                <li><Link to="/speciale">Speciale</Link></li>
                <li><Link to="/contact">Kontakt</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
